import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

type Props = {
  children: React.ReactNode;
  paddingX?: any;
  paddingY?: any;
  paddingTop?: any;
  position?: any;
  top?: any;
};

const Container = ({ children, ...rest }: Props) => (
  <Box
    maxWidth={{ sm: 720, md: 1236 }}
    width={"100%"}
    margin={"0 auto"}
    paddingX={2}
    paddingY={{ xs: 4, sm: 6, md: 8 }}
    {...rest}
  >
    {children}
  </Box>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;
